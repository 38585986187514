<template>
  <ul>
    <menu-options-item close label="Abrir" @click="$emit('abrir')" icon="folder-open" />
    <menu-options-item close label="Consultar comitente" />
    <menu-options-item close label="Consultar reboquista" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'remocao-list-menu',
  props: ['remocao'],
  components: {MenuOptionsItem}
}
</script>
