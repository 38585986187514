export default {
    title: '',
    menu: [
        {
            id: 'solicitacoes',
            title: 'Solicitações',
            icon: 'list',
            style: 'solid',
            url: '/#/remocao/solicitacoes'
        },
        {
            id: 'monitor',
            title: 'Monitor',
            icon: 'map-marker-check',
            style: 'solid',
            url: '/#/remocao/solicitacoes'
        },
        {
            id: 'relatorios',
            title: 'Relatório de custos',
            icon: 'calculator',
            style: 'solid',
            url: '/#/remocao/solicitacoes'
        },
        {
            id: 'cadastros básicos',
            title: 'Cadastros básicos',
            icon: 'cogs',
            style: 'solid',
            url: '/#/remocao/solicitacoes'
        }
    ]
}