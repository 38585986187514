<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="solicitacoes">
    <div>
      <search-filter>
        <form class="flex">
          <div class="row erp-row" style="max-width: 663px;">
            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Comitente"
              >
                <erp-select
                    placeholder="Qualquer"
                    :options="comitente.serverData"
                    v-model="filters.comitente" />
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 240px">
              <erp-s-field
                  view="tl"
                  label="Data de Solicitação (De)"
              >
                <datetime-input v-model="filters.dataSolicitacaoDe" simpleBorder />
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 240px">
              <erp-s-field
                  view="tl"
                  label="Data de Solicitação (Até)"
              >
                <datetime-input v-model="filters.dataSolicitacaoAte" simpleBorder />
              </erp-s-field>
            </div>

            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Status"
              >
                <erp-select
                    placeholder="Qualquer"
                    :options="status.serverData"
                    v-model="filters.status" />
              </erp-s-field>
            </div>
          </div>

          <div class="col flex m-t-sm def-list-buttons text-right" style="justify-content: flex-end">
            <div class="flex flex-center column m-r-xs">
              <u-btn @click="show" class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']"
                     @shortkey.native="pesquisar" />
              <span class="shortcut">Shift + Enter</span>
            </div>
            <div class="flex flex-center column m-r-xs">
              <u-btn class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                     @shortkey.native="clearFilters" />
              <span class="shortcut">Ctrl + Shift + R</span>
            </div>
            <div class="flex flex-center column">
              <u-btn class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']" @shortkey.native="novo" />
              <span class="shortcut">Shift + N</span>
            </div>
          </div>
        </form>
      </search-filter>

      <div class="erp-list">
        <u-table
            ref="table"
            color="primary"
            :columns="table.columns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]">
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>

            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr class="cursor-pointer" slot="body" slot-scope="props" :props="props">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td key="codigo" :props="props">
              {{props.row.codigo}}
            </u-td>
            <u-td key="solicitacao" :props="props">
              {{props.row.solicitacao}}
            </u-td>
            <u-td key="cliente" :props="props">
              {{props.row.cliente}}
            </u-td>
            <u-td key="canal" :props="props">
              {{props.row.canal}}
            </u-td>
            <u-td key="remocao" :props="props">
              {{props.row.remocao}}
            </u-td>
            <u-td key="situacao" :props="props">
              {{props.row.situacao}}
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <remocao-list-menu @abrir="solicitacaoShow(props.row.id)" :remocao="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../../layout/Layout'
import Menu from '../menuSolicitacoes'
import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DatetimeInput from '../../../reuse/input/Datetime'
import {UTable, UTr, UTh, UTd, UCheckbox} from 'uloc-vue'
import solicitacaoShow from '../windows/solicitacaoshow'
import MenuOptions from '../../layout/context-menu/context-window-options'
import RemocaoListMenu from './context/remocao-list-menu'

let filters = {
  comitente: null,
  dataSolicitacaoDe: null,
  dataSolicitacaoAte: null,
  status: null
}

export default {
  name: 'Solicitacoes',
  data () {
    return {
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: [
          {name: 'codigo', label: 'Código', field: 'codigo', align: 'left', sortable: true},
          {name: 'solicitacao', label: 'Solicitação', field: 'solicitacao', align: 'left', sortable: true},
          {name: 'cliente', label: 'Cliente', field: 'cliente', align: 'left', sortable: true},
          {name: 'canal', label: 'Canal', field: 'canal', align: 'left', sortable: true},
          {name: 'remocao', label: 'Remoção', field: 'remocao', align: 'left', sortable: true},
          {name: 'situacao', label: 'Situação', field: 'codigo', align: 'left', sortable: true},
          {name: 'options', label: '', field: 'options', align: 'left', sortable: false}
        ]
      },
      comitente: {
        loading: true,
        serverData: []
      },
      status: {
        loading: true,
        serverData: []
      },
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted () {
    this.request({
      pagination: this.table.serverPagination,
      filter: this.table.busca
    })
  },
  methods: {
    request ({pagination, filter}) {
      this.table.loading = true
    },
    show () {
      console.log('Show..')
      this.solicitacaoShow()
    },
    solicitacaoShow: solicitacaoShow
  },
  components: {
    RemocaoListMenu,
    MenuOptions,
    Layout,
    SearchFilter,
    ErpSField,
    DatetimeInput,
    ErpSelect,
    UTable,
    UTr,
    UTh,
    UTd,
    UCheckbox
  },
  computed: {
    menu () {
      return Menu
    }
  },
  meta: {
    title: 'Solicitações de Remoção',
    name: 'Solicitações'
  }
}
</script>
