export default function (evt) {
    let window = 'solicitacao'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Solicitação de Remoção',
        width: '80%',
        height: '90%',
        minHeight: '500px',
        maxWidth: '900',
        backdrop: true,
        clickOutside: false,
        windowClass: 'erp-window',
        contentClass: 'overflow-hidden',
        props: {cliente: this.cliente}
    }, () => import('../components/window/SolicitacaoShow.vue'))
        .then((wid) => {
            console.log(wid)
        }) // return wid
}
